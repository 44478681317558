import axios from 'axios';

const url = process.env.VUE_APP_ROOT_API ;
const ccPortal = 'http://localhost:6601';
const opayApi  = 'http://localhost:6502';

export default {
  getWalletBalanceReport(userId){
    return axios.post(url + '/customer-care-portal/report/wallet-balance-report/'+userId).then(response =>response.data.payload)
      .catch((error) => { return error.response });
  },
  getWalletBalanceReportList(){
    return axios.get(url + '/customer-care-portal/report/wallet-balance-reports-list').then(response =>response.data.payload)
      .catch((error) => { return error.response });
  },
  downloadExcelReport(key){
    return axios.get(url + '/customer-care-portal/report/wallet-balance-report-download/'+key,{responseType: 'blob'}).then(response =>response)
    .catch((error) => { return error.response });
  },
  getPromoUsageReport(params){
    return axios.post(url + '/customer-care-portal/report/promo-usage-report/'+params.userId+'/'+params.fromDate+'/'+params.toDate).then(response =>response.data.payload)
      .catch((error) => { return error.response });
  },
  getDebitCreditReport(params){
    return axios.post(url + '/customer-care-portal/report/credit-debit-report/'+params.userId+'/'+params.fromDate+'/'+params.toDate).then(response =>response.data.payload)
      .catch((error) => { return error.response });
  },
  getDetailDebit(params){
    return axios.post(url + '/customer-care-portal/report/credit-debit-report/debit/'+params.userId+'/'+params.fromDate+'/'+params.toDate).then(response =>response.data.payload)
      .catch((error) => { return error.response });
  },
  getDetailCredit(params){
    return axios.post(url + '/customer-care-portal/report/credit-debit-report/credit/'+params.userId+'/'+params.fromDate+'/'+params.toDate).then(response =>response.data.payload)
      .catch((error) => { return error.response });
  },
  getBalanceByTopup(params){
    return axios.post(url + '/customer-care-portal/report/balance-by-topup/'+params.fromDate+'/'+params.toDate).then(response =>response.data.payload)
      .catch((error) => { return error.response });
  },
  getTotalFeesCollected(params){
    return axios.post(url + '/customer-care-portal/report/fee-collected/'+params.fromDate+'/'+params.toDate).then(response =>response.data.payload)
      .catch((error) => { return error.response });
  },
  getAccLegsEntries(trxID){
    return axios.post(url + '/customer-care-portal/report/movement-legs/'+trxID).then(response =>response.data.payload)
      .catch((error) => { return error.response });
  },
  getReportExcel(params){
    return axios.post(url + '/customer-care-portal/report/excel/generate-excel-with-password',params,{responseType: 'blob'}).then(response =>response)
      .catch((error) => { return error.response });
  }
};
