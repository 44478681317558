<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="5">
                    <h4>M-Coins</h4> 
              </CCol>              
              <CCol md="5">
                   <CInput label="Search" v-model="filter" @change="getFilterData" placeholder="Type to Search" horizontal />                   
              </CCol>
              <CCol md="2">                 
                    <!-- <vue-excel-xlsx 
                        style="color:green"                       
                        :data="data"
                        :columns="columns"
                        :filename="'M-Coins'"
                        :sheetname="'M-Coins'"
                        >
                        Download
                    </vue-excel-xlsx>                  -->
                    <CButton v-on:click="showFormPasswd()" color="success">Download</CButton>
              </CCol>
          </CRow>
        </CCardHeader><br/>
        <CCardBody>

          <CRow>
            <CCol col="12" class="text-left">                   
               <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
            </CCol> 
          </CRow>         

          <CRow>
              <CCol col="12" class="text-center">                   
                <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
              </CCol> 
          </CRow>

                    <b-card-group deck class="mb-3" style="width:50%" v-if="formPasswd">  
                        <b-card border-variant="dark" header="Protected Excel Password" align="center">
                        <b-card-text>
                            <b-form-input style="text-align: center" v-model="password" size="md" :readonly="true"></b-form-input>
                        </b-card-text>                                                                      
                        <b-button-group>
                           <b-button v-on:click="actionCancel()">Cancel</b-button>
                           <b-button variant="success" v-on:click="generateWorkbook()">Download</b-button>
                        </b-button-group>                                   
                        </b-card>
                     </b-card-group>



         <CRow>              
              <CCol md="4">                    
                    <CInput
                        label="Expiry From Date"
                        type="date"
                        vertical
                        v-model="fromDate" 
                        @change="startDateOnChange" 
                      />       
              </CCol>
              <CCol md="4">                    
                    <CInput
                        label="Expiry To Date"
                        type="date"
                        vertical
                        v-model="toDate"
                        @change="endDateOnChange"                       
                      />         
              </CCol>  
              <CCol col="4" class="text-center"> 
                 <div class="form-group" style="text-align: left;">
                 <label for="input-small">Status</label>                  
                 <b-form-select v-model="defOptSts" :options="optStatus" v-on:change="handleStsChange"></b-form-select>
                 </div>
              </CCol> 
          </CRow>      

        <div class="table-responsive scroll-y">
          <b-table striped hover :items="items" :fields="fields" v-model="list" :current-page="currentPage" :per-page="perPage" :filter="filter"> 

              <template v-slot:cell(pointExpiryDateTime)="{ item }"> 
                {{ item.pointExpiryDateTime }}
              </template>             

          </b-table>
        </div>

        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>      
        </div>
          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import AuthService from '@/api/AuthService.js';
import ReportService from '@/api/ReportService.js';
import moment from 'moment';

 export default {
    name: "mcoins",
    components: {},     
    data() { var date = new Date();
      return {
        spinner:false,
        list:[],
        columns : [],
        data : [],
        items:[],
        cfilter:[],             
        fields: [
          {
            key: 'userId',
            label : 'User Id',
            sortable: true
          },
          {
            key: 'mobileNumber',
            label : 'Mobile Number',
            sortable: true
          }, 
          {
            key: 'displayName',
            label : 'User Name',
            sortable: true
          }, 
          {
            key: 'pointAmount',
            label : 'Point Amount',
            sortable: true
          },
          {
            key: 'pointExpiryDateTime',
            label : 'Point Expiry DateTime',
            sortable: true
          },          
          {
            key: 'provider',
            label : 'Provider',
            sortable: true
          },           
          {
            key: 'currentStatus', 
            label : 'Status',
            sortable: true
          },
          {
            key: 'pointCancelledBy',
            label : 'Canceled By',
            sortable: true
          }
        ],         
        currentPage: 1,
        perPage: 10,
        filter: null,
        //fromDate:null,
        fromDate: new Date(date.getFullYear(), date.getMonth(), 2).toISOString().substr(0, 10),
        //toDate:null, 
        toDate: new Date().toISOString().substr(0, 10),  
        defOptSts:'ALL', 
        optStatus: [
            {text:'ALL',value:'ALL'},
            {text:'COMPLETED',value:'COMPLETED'},
            {text:'CANCELED',value:'CANCELED'},
            {text:'EXPIRED',value:'EXPIRED'},             
        ], 
        formPasswd:false,
        filename:"mcoins-report.xlsx",
        password:"",
        seen:false,
        msg :'',
        color:''
      };
    },    
    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },        
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        },
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },
        getGeneraldate(value){
            var dt  = new Date(value);
            var dt2 = dt.toISOString();
            var temp = dt2.split(".");
            value = temp[0].replace("T"," ");
            return value;
        },
        formatNum(value){ 
           if(!value) return 0  
           return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")          
        }
      },

    created () { 
       this.spinner = true;   
       this.setDefaultPasswd();     
       this.setColumns();
       this.retrivePoints();  
       this.getConfigList();       
    },
    methods: {

      showFormPasswd(){
         this.formPasswd = true;
      },

      actionCancel(){
          this.formPasswd = false;
      },
       
      setDefaultPasswd(){
        var today = moment().format('DDMMYYYYHHmmss');
        var user  = this.$store.getters.getUser; 
        this.password = user+today;
      },
      
      generateWorkbook(){

         this.formPasswd = false; this.spinner=true;
         var cols  = this.columns; var rows  = this.data;        
         var heads = []; var temp =[]; var data=[];
         let params = {};   var colkey =[];  
         
         var tzoffset = (new Date()).getTimezoneOffset() * 60000;

          for (var key in cols) {
             var col = cols[key]; heads.push(col.label);
             colkey.push(col.field);
          } 
          data.push(heads);  var jum = heads.length;          

          for (var key2 in rows) { var row = rows[key2]; 
              var init = Array(jum).fill(null);
              for(var key4 in colkey){
                for (var key3 in row) { 
                  var val  = row[key3]; 
                  if(key3==="pointExpiryDateTime"){
                    //var val = new Date(val).toISOString().substr(0, 19).replace("T"," ");
                    var ts = new Date(val).getTime();
                    var val = (new Date(ts-tzoffset)).toISOString().substr(0, 19).replace("T"," ");
                  }                
                  var ckey = colkey[key4]; 
                  if(key3===ckey){  init[key4] = val; }              
                }
              }
              data.push(init); 
          } 

         params['password'] = this.password;
         params['data'] = data;   //console.log(params); 
         this.getReportExcel(params);
      },

      getReportExcel: function(params) {
        ReportService.getReportExcel(params).then(resp => {             
         try {  
            if(resp.status==200){
               var filename = this.filename;
               const link = document.createElement('a');    
               link.href = window.URL.createObjectURL(
                   new Blob([resp.data])
               );   
               link.setAttribute('download', filename);
               document.body.appendChild(link);
               link.click(); this.spinner = false; 
            } else {
              this.msg   = 'Sorry, Generated Excel Failed!'; 
              this.color = 'warning'; this.seen  = true;
              this.spinner = false; 
              setTimeout( () =>  this.seen=false , 5000);
            }
           }
         catch(err) {            
            this.msg   = 'Sorry, Generated Excel Failed!'; 
            this.color = 'warning'; this.seen  = true;
            this.spinner = false; 
            setTimeout( () =>  this.seen=false , 5000);
         } 

        }, error => { 
           this.spinner = false;            
        });
      }, 

      dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
      }, 
      
      setDataRows(resp,keydate){

          var tzoffset = (new Date()).getTimezoneOffset() * 60000;

          for (var key in resp) {                
              var resp2 = resp[key];  
                for (var key2 in resp2) { 
                    if(key2 === keydate){                        
                       var strdate = resp2[key2]; 
                       var ts = new Date(strdate).getTime();
                       var dt = (new Date(ts-tzoffset)).toISOString().substr(0, 19).replace("T"," ");
                       //var dt = new Date(strdate);
                       resp[key][keydate] = dt;   
                    }                   
                 }
            }

          this.data  = resp;
      }, 
      
      handleStsChange(){
          var status =  (this.defOptSts=="ALL") ? null : this.defOptSts;
          this.filter = status;
      },      

      startDateOnChange(){ //console.log(this.fromDate+'|'+this.toDate);

        this.spinner=true;

        this.items = this.cfilter;
                 
        var fromDate = new Date(this.fromDate+" 00:00:00").getTime();
        var toDate   = new Date(this.toDate+" 23:59:59").getTime();
         
        var result  = this.items.filter(a => {   
          var rdate   = a.pointExpiryDateTime;      
          //var rdate  = a.pointExpiryDateTime.toString().replace("T"," "); //console.log(rdate);
          var date   = new Date(rdate).getTime();  //console.log(rdate+'|'+date+'|'+fromDate +'|'+toDate ); 
              return (date >= fromDate && date <= toDate);                  
         }); //console.log(result);

         this.items = result; 
         this.data = result; this.spinner=false;
         
      },

      endDateOnChange(){
          this.startDateOnChange();
      },      

      getFilterData(){          
         resp  = this.list;
         this.setDataRows(resp,'pointExpiryDateTime');
      },

      setColumns(){
        var fields = this.fields; let row = {}; var cols = [];
        for (var key in fields) {           
           row['label'] = fields[key].label;
           row['field'] = fields[key].key;
           var temp = JSON.stringify(row); cols.push(temp);
        }        

        for (var key2 in cols) {               
           var row2 = JSON.parse(cols[key2]); this.columns.push(row2);
        }
      },

      retrivePoints(){
        let params = {}; 
        params['id']     = "ALL";
        params['status'] = "ALL";
        this.getUserPoints(params);
      },  
      
      getConfigList: function() { 
          AuthService.getConfigList().then(resp => {    

            var typeSrc=[];
            var arrTypes = resp.promoStatus;
            for (const item2 of arrTypes) {                    
                if(item2!=="PENDING" && item2!=="FAILURE") { typeSrc.push({ value: item2 , text: item2 }); }
            }
            typeSrc.push({ value: 'ALL'  , text: 'ALL' });
            this.optStatus = typeSrc;

           }, error => {
            console.log('error');    
          });
      },     

      getUserPoints: function(params) {
        AuthService.getUserPoints(params).then(resp => {   //console.log(resp);   

            for (var key in resp) {                
              var resp2 = resp[key];
                for (var key2 in resp2) { 
                    if(key2=="receiverReference"){  
                        resp[key]['userId'] = resp2[key2].userId;
                    }
                 }
            }

            this.cfilter = resp;
            this.items   = resp;           
            this.setDataRows(resp,'pointExpiryDateTime');
            this.startDateOnChange();
            this.spinner = false; 

        }, error => {
          this.loading = false;
        });
      },     

    }
 }; 

</script>

<style>
    .scroll-y{ overflow-y:scroll;}    
</style>